.seven-parnet {
  background-image: url("https://storage.googleapis.com/anima-react/ninebackground.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  padding-top: 64px;
}
.seven-parnet {
  font-family: "Space Grotesk", sans-serif;
  color: #ffffff;
}

.seven-parnet span {
  font-family: "base", sans-serif;

  font-size: 21px;
  font-weight: 300;
  line-height: 47px;
  text-align: left;
  color: #ffffff;
}
.seven-parnet ul {
  list-style: none; /* Remove default list styles */
  padding-left: 0; /* Remove default list indentation */
}

.seven-parnet ul:before {
  content: "";
  display: inline-block;
  width: 10px; /* Adjust width as needed */
  height: 10px; /* Adjust height as needed */
  background-color: #ffffff; /* White color */
  margin-right: 10px; /* Adjust margin as needed */
}

.seven-parnet ul {
  font-family: "base", sans-serif;

  font-size: 35px;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: left;
}

.seven-parnet p {
  font-family: "Space Grotesk", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: center;
}
.btn {
  background-color: transparent;
  font-family: "Space Grotesk", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 52px;
  letter-spacing: 0.109em;
  text-align: center;
  color: #ffd23e;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
}
.beside-image{
  display: flex;
  justify-content: center;
  align-items: left;
  width: 500px;
  height: auto;
}

@media screen and (max-width: 767px) {
  .first-image-beside{
    width: 200px;
  }
  .row{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
  }
  .seven-parnet p {
    font-family: "Space Grotesk", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center !important;
    
  } 
  .nine-btns{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
    cursor: pointer;
  }
}
