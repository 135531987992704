/* Style for modal backdrop */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  background-color: rgba(0, 0, 0, 1) !important; /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Style for modal content */
.modal-content {
  background-color: #000 !important; /* White background for modal content */
  padding: 20px;
  width: 90%;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Shadow effect */
}
