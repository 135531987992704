.become-fourth-parnet{
  background-image: url("https://storage.googleapis.com/anima-react/thirdsection.webp");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}
.become-fourth-parnet .row {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Space Grotesk", sans-serif;
  color: #ffffff;

}
.up{
  color: #92E204;
  font-size: 50px;
  font-weight: 400;
  padding: 0 16px;
  line-height: 104px;
  letter-spacing: 0.052em;
  text-align: left;
  font-family: "styled", sans-serif;
}
.down{
  color: #C82F5A;
  font-size: 50px;
  font-weight: 400;
  line-height: 104px;
  letter-spacing: 0.052em;
  text-align: left;
  font-family: "styled", sans-serif;
  padding: 0 16px;

}
.title{
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Space Grotesk", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
text-align: center;
}

.trade{
  width:100%;
}

@media screen and (max-width: 767px) {
  .row{
    font-size: 14px;
 text-align: center;
 padding: 32px;
  }
  .become-fourth-parnet{
    padding-top: 0 !important;
  }
  
}
