
.btn2 {
  width: 100%;
  background-color: transparent;
  font-family: "Space Grotesk", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 52px;
  letter-spacing: 0.109em;
  text-align: center;
  color: #ffff;
  border: none !important;
  text-transform: capitalize;
  display: flex;
}
.matic-crypto-coin-width{
  width: 76%;
   margin-inline: auto;
}
.btn.focus, .btn:focus {
  outline:none; 
   box-shadow: none
}
.btn2:focus,
.btn2:active {
  outline: none; /* Removing the outline when button is focused or active */
}
.btn2 .btn-header-link {
  border: none !important;
  outline: none ;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.matic-coin-style{
  width: 100% !important;
}

#main {
  background-image: url("https://storage.googleapis.com/anima-react/tenbackground.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  padding-top: 64px;
}
.card {
  background-color: transparent !important;
  margin: 16px;
}
.accordion {
  border: 1px solid #ffd23e;
  border-radius: 10px;
  width: 100%;
}
.card-body {
  font-family: "Space Grotesk", sans-serif;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}
.card-header,
.card-body {
  background-color: #150c25;
}
.card-header {
  border: 1px solid #ffd23e;
}
.arrow-wrapper {
  float: right;
  margin-top: 6px; /* Adjust as needed */
}

.arrow {
  display: flex;
  align-items: right;
  justify-content: right;
  font-size: 1.5rem; /* Adjust as needed */
}
.text span {
  font-family: "Space Grotesk", sans-serif;
  font-size: 21px;
  font-weight: 300;
  line-height: 47px;
  letter-spacing: 0em;
  color: #ffffff;
  text-align: left;
}
.text h2:before {
  display: flex;
  justify-content: center;
  align-items: center;
  content: "";
  display: inline-block;
  width: 10px; /* Adjust width as needed */
  height: 10px; /* Adjust height as needed */
  background-color: #ffffff; /* White color */
  margin-right: 10px; /* Adjust margin as needed */
}
.text h2 {
  color: #ffffff;
}
.text p {
  color: #ffffff;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.back {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #a128c4;
  border-radius: 50%;
  height: 80px;
  width: 80px;
}
.img-with-drop-eight {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

@media (max-width: 576px) {
  .matic-crypto-coin-width{
    width: 100%;
     margin-inline: auto;
  }
  .btn-header-link {
    width: 200px;
  }
  .card-body {
    font-size: 12px !important;
  }
  .img-with-drop-eight {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .img-with-drop{
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
