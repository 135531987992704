/* InvestmentBar.module.css */

.investment-bar {
  border: 2px solid #f4d56f; 
  padding: 20px; 
  border-radius: 14px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.col-item {
  margin-bottom: 10px;
  border: #f4d56f;
  flex: 0 0 calc(50% - 10px); /* Adjust the width as needed */
}

.investment-item button {
  border: 1px solid #f4d56f;
  background-color: transparent ;
  border-radius: 10px;
  width: 100%;
}
.special-button{
  background-color: #f4d56f !important; 

}
.big-button {
  background-color: #f4d56f; 
  color: #000000; 
  padding: 10px 20px; 
  border-radius: 6px; 
  width: 100%;
  font-family: "Space Grotesk", sans-serif;
font-size: 18px;
font-weight: 400 !important;
line-height: 38px;
  text-align: center;

}
.investment-number{
  color: #f4d56f !important ;
}
.highlighted-investment-number{
  display: flex ;
  justify-content: center ;
  align-items: center ;
  color: #2F2341 !important ;
  font-family: "base", sans-serif;
    font-size: 33px;
    font-weight: 700 !important;

}
.button-content-investent{
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .col-item {
    width: 100%;
  }
  .row{
    margin: 0 !important;
  }
}
@media screen and (min-width: 1000px) {
  .big-button {
    background-color: #f4d56f; 
    color: #000000; 
    padding: 10px 20px; 
    border-radius: 6px; 
    font-size: 16px; 
    width: 100%;
  }
}