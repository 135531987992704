.table-parnet{
  background-image: url("https://storage.googleapis.com/anima-react/girle.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
 padding: 5vh;
}
.table-parnet {
  font-family: "Space Grotesk", sans-serif;
  color: #ffffff;

}

.table-parnet span {
  font-family: "base", sans-serif;

  font-size: 21px;
  font-weight: 300;
  line-height: 47px;
  text-align: left;
  color: #ffffff;
}
.table-parnet ul {
  list-style: none; /* Remove default list styles */
  padding-left: 0; /* Remove default list indentation */
}

.table-parnet ul:before {
  content: "";
  display: inline-block;
  width: 10px; /* Adjust width as needed */
  height: 10px; /* Adjust height as needed */
  background-color: #ffffff; /* White color */
  margin-right: 10px; /* Adjust margin as needed */
}


.table-parnet ul {
  font-family: "base", sans-serif;

  font-size: 35px;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: left;
}

.table-parnet p {
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 0em;
  text-align: left;
  font-family: "base", sans-serif;

}
.affillite-crypto-table {
  padding-top: 5vh;
}

@media screen and (max-width: 767px) {
  .affillite-crypto-table {
  }
}

@media screen and (min-width: 768px) and (max-width: 1600px) {
  .affillite-crypto-table {
    padding-top: 5vh !important; /* Adjust padding for screens between 768px and 1600px width */
  }
}

@media screen and (min-width: 1024px) {
  .affillite-crypto-table {
    padding-top: 10vh !important; /* Adjust padding for screens between 768px and 1600px width */
  }
}
