.sec-4-right-item {
  display: flex;
  margin-top: -40px;
  color: #fff;
  align-items: center;
}

.sec-4-right-item p {
  padding: 0;
  margin: 0;
  margin-right: 10px;
}
