.sec-4-home {
  width: 100vw;
  height: 100vh;
  margin: auto;
  display: flex;
  background-image: url("https://storage.googleapis.com/anima-react/home/home-sec-4.png");
  flex-direction: column;
  align-items: center;
}
.footer-container {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;

}

.footer-text {
  position: absolute;
  margin-top: 55px;
  color: #fff;
}

.sec-8-bottom {
display: flex; 
justify-content: flex-start ;
align-items: center;
 color: #f4f4f4;
  font-size: 14px;
  text-align: left;
  float: left ;
}
.sec-8-head {
  margin-top: -60px;
  text-align: left;
  color: #ffffff;
}
.sec-4-head-right {
  margin-top: -60px;
  text-align: left;
  color: #f4f4f4;
}
.accordion-faq {
  height: 100%;
}

.sec-8-table {
  z-index: 889;
  background-image: url("https://storage.googleapis.com/anima-react/faqContainer.png");
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  width: 1400px;
  background-repeat: no-repeat;
  height: 700px;
  max-height: 1000px;
  background-size: contain;
  display: flex;
  flex-direction: column;
  margin: auto;
}
.sec-8-table-inner {
  display: flex;
  flex-direction: column;
  height: 93%;
  margin-top: 20px;
  width: 98%;
  margin-inline: auto;
  overflow-y: scroll;
}

@media (max-width: 1900px ) {
  .sec-8-table {
    width:800px !important;
    max-width: 1200px;
    height: 390px;
    max-height: 1000px;
  }
}
@media (max-width: 1200px) {
  .sec-8-table {
    width:600px !important;
    max-width: 1200px;
    height: 390px;
    max-height: 1000px;
  }
}
@media (max-width: 800px) {
  .footer-container {
    display: block;
    left: 0;
  }
  .footer-text {
    color: #fff;
  }
  .sec-8-table {
    width: 98% !important;
    height: 280px;
  }
}

@media (max-width: 576px) {
  .title {
    font-size: 10px !important;
  }
  .sec-8-table {
    width: 320px;
    height: 380px;
    background-size: cover;
    border: 1px solid #b9992d;
  }
}
