.third-parnet {
  background-image: url("https://storage.googleapis.com/anima-react/fifthsection.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  padding-top: 64px;
}
.third-title {
  font-family: "Space Grotesk", sans-serif;
  font-size: 63px;
  font-weight: 500;
  line-height: 67px;
  letter-spacing: 0em;
  text-align: center;
}
.first,
.third {
  font-family: "Space Grotesk", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 356px;
  height: 323px;
}
.first h2,
.third h2,
.second h2 {
  font-family: "Space Grotesk", sans-serif;
  font-size: 65px;
  font-weight: 700;
  line-height: 83px;
  letter-spacing: 0em;
  text-align: center;
}
.first p,
.third p,
.second p {
  font-family: "Space Grotesk", sans-serif;
  font-size: 23px;
  font-weight: 300;
  line-height: 52px;
  letter-spacing: 0em;
  text-align: center;
}
.second h2,
.second p {
  color: #ffd23e;
}
.second {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #ffd23e;
  width: 356px;
  height: 323px;
}
.second img {
  position: absolute;
  right: 0 !important;
  top: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
}
.base-btn {
  position: absolute;
  left: 60px;
  bottom: -19px;
  padding: 0;
  margin: 0;
}
.custom-btn{
color: #ffd23e !important;
background-color: none;
border: none !important;
height: 60px;
text-transform: capitalize;
position: absolute;
bottom: -100px;
backdrop-filter: blur(0);

}
.custom-btn::after{
  border: none;
}

@media screen and (max-width: 767px) {
  .first ,
.third ,
.second  {
  height: auto;
}
.first h2,
.third h2,
.second h2 {
  font-family: "Space Grotesk", sans-serif;
  font-size: 39px;
  font-weight: 500;
  line-height: 52px;
  letter-spacing: 0em;
  text-align: center;
  
}
.third-title{
font-size: 28px;
font-weight: 500;
line-height: 39px;
letter-spacing: 0em;
text-align: center;
}
.first ,
.third ,
.second  {
  padding-top: 32px;
  margin: 8px;
  border: 1px solid #ffffff;
  backdrop-filter: blur(10px);
  border-radius: 10px;
}
.base-btn {
  left: 12svw;

}
.third{
  margin-top: 85px;
}
.third-plans{
  padding: 0 !important;
  margin: 0 !important;
}
}
