.main-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-title > * {
  flex: 1; /* Equal width for both children */
}
.sec-4-head {
  flex: 5; /* Take more width */
}

.sec-4-subtitle {
  flex: 1; /* Take less width */
}

.sec-4-head,
.sec-4-subtitle {
  margin-top: -60px;
  text-align: left;
  color: #ffffff;
}

.sec-00-home {
  width: 100vw;
  height: 100vh;
  margin: auto;
  display: flex;
  /* background-image: url("https://storage.googleapis.com/anima-react/home/home-sec-4.png"); */
  flex-direction: column;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
}

.table-data2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 98%;
  margin-left: 20px;
}
.table-data2 p:nth-child(1) {
  text-align: left !important;
  width: 50% !important;
}

.table-data2 p:nth-child(2) {
  text-align: left !important;
  width: 100% !important;
}
.table-data2 p:nth-child(3) {
  text-align: left !important;
  width: 75% !important;
}
.table-data2 p:nth-child(4) {
  text-align: left !important;
  width: 80% !important;
}
.table-data2 p:nth-child(5) {
  text-align: left !important;
  width: 100% !important;
}
.table-data2 p:nth-child(6) {
  text-align: left !important;
  width: 100% !important;
}
.table-data2 p {
  text-align: left !important;
  color: #fff;
  width: 100%;
}
.table-data2 p:nth-child(1) {
  margin-left: 20px  ;
  text-align: left !important;
  width: 50% !important;
}

.table-data2 p:nth-child(2) {
  text-align: left !important;
  width: 100% !important;
}
.table-data2 p:nth-child(3) {
  text-align: left !important;
  width: 75% !important;
}
.table-data2 p:nth-child(4) {
  text-align: left !important;
  width: 80% !important;
}
.table-data2 p:nth-child(5) {
  text-align: left !important;
  width: 100% !important;
}
.table-data2 p:nth-child(6) {
  text-align: left !important;
  width: 100% !important;
}

.sec-4-table {
  z-index: 888;
  background-image: url("https://storage.googleapis.com/anima-react/tableborder.png");
  width: 1400px;
  background-repeat: no-repeat;
  height: 800px;
  background-size: contain;
  display: flex;
  flex-direction: column;
  margin: auto;
}
.sec-4-table-inner {
  display: flex;
  flex-direction: column;
  height: 65%;
  margin-top: 80px;
  width: 99%;
  margin-inline: auto;
  overflow-y: scroll;
}

::-webkit-scrollbar {
  width: 8px;
  border-radius: 20px; /* width of the scrollbar */
}

/* Track (the area on which the thumb moves) */
::-webkit-scrollbar-track {
  border-radius: 20px;
  background: transparent; /* color of the track */
}

/* Thumb (the draggable scrolling handle) */
::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ffd23e; /* color of the thumb */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b9992d; /* color of the thumb on hover */
}

@media (max-width: 1200px) {
  .main-title {
    margin-top: -10px;
  }
  .sec-4-subtitle {
    font-size: 12px !important;
  }
  .sec-4-table {
    z-index: 888;
    background-image: url("https://storage.googleapis.com/anima-react/home/sec-4-chart.png");
    width: 700px;
    background-repeat: no-repeat;
    height: 400px;
    background-size: contain;
    display: flex;
    flex-direction: column;
    margin: auto;
  }
  .table-data2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 98%;
    margin-left: 0px;
  }
  .table-data2 p:first-child {
    text-align: left !important;
    width: 15%;
  }
  .sec-4-table-inner {
    display: flex;
    flex-direction: column;
    height: 65%;
    margin-top: 50px;
    width: 680px;
    margin-inline: auto;
    overflow-y: scroll;
  }
}
@media (max-width: 576px) {
  .sec-4-head {
    margin-top: -60px;
    text-align: left;
    color: #ffffff;
    font-size: 16px;
  }
  .sec-4-subtitle {
    margin-top: -60px;
    text-align: left;
    color: #ffffff;
    font-size: 12px;
  }

  .sec-4-home {
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
  }
  .sec-4-table {
    z-index: 888;
    background-image: url("https://storage.googleapis.com/anima-react/home/sec-4-chart-sm.png");
    width: 320px;
    background-repeat: no-repeat;
    height: 700px;
    background-size: contain;
    display: flex;
    flex-direction: column;
    margin: auto;
  }
  .table-data2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 98%;
    margin-left: 0px;
  }
  .table-data2 p:nth-last-child(-n + 3) {
    display: none;
  }
  .table-data2 p:first-child {
    text-align: left !important;
    width: 40%;
  }
  .sec-4-table-inner {
    display: flex;
    flex-direction: column;
    height: 65%;
    margin-top: 50px;
    width: 270px;
    margin-inline: auto;
    overflow-y: scroll;
  }
  .sec-4-head {
    flex: 1; /* Take more width */
  }

  .sec-4-subtitle {
    flex: 1; /* Take less width */
  }
}
