
.six-parnet {
  background-image: url("https://storage.googleapis.com/anima-react/girle.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  padding-top: 64px;
}
.six-parnet .row {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Space Grotesk", sans-serif;
  color: #ffffff;
}
.up {
  color: #92e204;
  font-size: 50px;
  font-weight: 400;
  padding: 0 16px;
  line-height: 104px;
  letter-spacing: 0.052em;
  text-align: left;
  font-family: "styled", sans-serif;
}
.down {
  color: #c82f5a;
  font-size: 50px;
  font-weight: 400;
  line-height: 104px;
  letter-spacing: 0.052em;
  text-align: left;
  font-family: "styled", sans-serif;
  padding: 0 16px;
}
.up-percent {
  color: #92e204;
  font-size: 50px;
  font-weight: 400;
  padding: 0 16px;
  line-height: 104px;
  letter-spacing: 0.052em;
  text-align: left;
}
.down-percent {
  color: #c82f5a;
  font-size: 50px;
  font-weight: 400;
  line-height: 104px;
  letter-spacing: 0.052em;
  text-align: left;
    padding: 0 16px;
}
.six-title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Space Grotesk", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
}

.trade {
  width: 100%;
}

@media screen and (max-width: 767px) {
  .six-parnet {
    padding-top: 0 !important;
  }
  .row {
    font-size: 14px;
    text-align: center;
    padding: 32px;
  }
  .six-title {
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;
  }
  .up,.down{
    padding: 0 !important;
font-size: 28px;
font-weight: 500;
line-height: 38px;
letter-spacing: 0em;
text-align: center;

  }
  .row{
    padding: 15px;
  }
}
