.eight-parnet {
  background-image: url("https://storage.googleapis.com/anima-react/thirdsection.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  padding-top: 64px;
}
.eight-parnet {
  font-family: "Space Grotesk", sans-serif;
  color: #ffffff;
}

.eight-parnet span {
  font-family: "base", sans-serif;

  font-size: 21px;
  font-weight: 300;
  line-height: 47px;
  text-align: left;
  color: #ffffff;
}
.eight-parnet ul {
  font-family: "Space Grotesk", sans-serif;

  list-style: none; /* Remove default list styles */
  padding-left: 0; /* Remove default list indentation */
}

.eight-parnet ul:before {
  content: "";
  display: inline-block;
  width: 10px; /* Adjust width as needed */
  height: 10px; /* Adjust height as needed */
  background-color: #ffffff; /* White color */
  margin-right: 10px; /* Adjust margin as needed */
}

.eight-parnet ul {
  font-family: "base", sans-serif;

  font-size: 35px;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: left;
}

.eight-parnet p {
  font-family: "Space Grotesk", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: left;
}
.eight-parnet .row {
  display: flex;
  justify-content: center;
  align-items: center;
}
.eight-btn {
  background-color: transparent;
  font-family: "Space Grotesk", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 52px;
  letter-spacing: 0.109em;
  text-align: center;
  color: #ffd23e;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  border: none;
}
.eight-btn:active {
  border: none !important;
}
.side-image {
  padding-top: 0 !important;
  margin-top: 0 !important;
  width: 500px;
  height: auto;
  vertical-align: top !important ;
}
.col-md-6 {
  padding-top: 0 !important;
  margin-top: 0 !important;
}
.base-btn-eight{
  display: flex;
  flex-direction: row;
}
@media screen and (max-width: 767px) {
  .side-image {
    padding-top: 0;
    margin-top: 0;
    width: auto;
    height: auto;
  }
  .eight-parnet ul:before {
    content: "";
    display: none;
  }

  .eight-parnet ul {
    font-family: "base", sans-serif;
    font-size: 28px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
  }
  .eight-parnet h2 {
    font-family: "Space Grotesk", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;
  }
  .eight-parnet p {
    font-family: "Space Grotesk", sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
  }
.base-btn-eight{
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

}
