.btn-wrapper {
  background: #ffd23e;
  max-width: 350px;
  width: 250px;
  min-width: 200px;
  height: 5vh;
  cursor: pointer;
  border: none;
  font-style: normal !important;
  font-size: 1.5rem;
  text-align: center;
  transform-origin: bottom left;
  transform: skew(-13deg, 0);
  font-family: "base", sans-serif;
  font-weight: 600;
  text-transform: capitalize;
  transition: background-color 0.3s, box-shadow 0.3s, transform-origin 0.3s,
    transform 0.3s;
  color: #120E24;
  
}
.b-b-t{
  transform: none !important;

}
.btn-wrapper:hover {
  background-color: #ffecb3; /* Change background color on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add box shadow on hover */
  transform-origin: top left;
  transform: skew(-13deg, 0);
}
.base-btn {
  display: flex;
  justify-content: left;
  align-items: left;
}
@media screen and (max-width: 767px) {
  .base-btn,.btn-wrapper     {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn-wrapper{
    width: auto;
    width: 241.26px;
    height: 49.53px;
    font-size: 16px  ;

  }
  .base-btn {
   font-size: 12px  ;
  }
}