.container {
  padding-top: 15vh;
}
#hero-video {
  width: 100% !important;
  height: 100% !important;
  z-index: 999 !important;
  background-color: black;
}
.polygon {
  cursor: pointer;
}
.row {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.new-title {
  color: #ffd23e;
  font-size: 63px;
  font-weight: 500;
  line-height: 67px;
  letter-spacing: 0em;
  text-align: left;
  font-family: "base", sans-serif;
}
span {
  color: #ffd23e;
  font-size: 65px;
  font-weight: 400;
  line-height: 104px;
  letter-spacing: 0.052em;
  text-align: left;
  font-family: "styled", sans-serif;
}
.subtitle {
  color: #ffffff;
  font-family: "base", sans-serif;
  font-size: 32px;
  font-weight: 300;
  line-height: 47px;
  letter-spacing: 0em;
  text-align: left;
}
.subtitle-1 {
  color: #ffffff;
  font-family: "base", sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 47px;
  letter-spacing: 0em;
  text-align: left;
}
.subtitle-2 {
  color: #ffd23e;
  font-family: "base", sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 47px;
  letter-spacing: 0em;
  text-align: left;
}
.rotate-title {
  transform: rotate(6deg); /* Rotate the text counter-clockwise */
  margin-bottom: 20px; /* Add margin to prevent overlapping */
}
.beside-img {
  margin-top: 20vh !important ;
}
.first-btn {
  background: transparent;
  border: 2px solid white !important;
  backdrop-filter: blur(10px);
  color: white !important;
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;
  width: 114%;
  font-family: "Space Grotesk", sans-serif;
  font-size: 16px; /* Removed space between 12 and px */
  font-weight: 300;
  line-height: 52px;
  letter-spacing: 0.109em;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.running-aff{
  margin: auto;
  position: absolute;
  right: 19%;
  top: 200px;
}

.first-btn img {
  margin-right: 10px; /* Adjust the margin as needed */
}

.polygon {
  display: block;
  text-align: left;
  padding: 8px 144px;
}
@media screen and (max-width: 767px) {

  .container {
    padding-top: 2vh;
  }
  .new-title {
    font-size: 28px;
    font-weight: 500;
    line-height: 38px !important;
    text-align: left;
    font-family: "base", sans-serif;
    padding-top: auto;
  }
  .subtitle,
  .subtitle-1,
  .subtitle-2 {
padding:8px 0;  }
  .new-title,
  span {
    line-height: 100%;
  }
  .subtitle-1 {
    padding: 6px;
  }
  .span {
    font-size: 28px;
    font-weight: 400;
    line-height: 2px !important;
    letter-spacing: 0.052em;
    text-align: left;
  }

  .girle-img {
    margin-top: 16px;
    width: 200px;
  }
  .girle-img-1 {
    width: 200px;
  }
  .span-text {
    font-size: 32px;
  }
  .subtitle {
    font-size: 16px;
    line-height: 33px;
  }
  .first-btn {
    padding-top: 20px;
    width: 80%;
    font-size: 14px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border-radius: 0;
font-size: 12px;
font-weight: 300;
line-height: 52px;
letter-spacing: 0.109em;
text-align: center;

  }
  .first-btn img {
    display: none;
  }
  .watchaff:hover {
    color: #ffd23e;
  }
  .polygon {
    display: block;
    text-align: left;
    padding: 8px 64px;
    cursor: pointer;
  }
  .polygon:hover {
    color: #ffd23e;
  }
  .beside-img {
    /* margin-top: 0 !important; */
    display: none !important;
  }
  #hero-video {
    width: 100% !important;
    height: 100% !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
  .girle-img,.girle-img-1{
    width: 250px;
  }
  .container {
    padding-top: 0;
  }
  .first-btn {
    padding-top: 20px;
    width:250px;
    font-size: 10px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  .first-btn img {
    display: none;
  }
  .beside-img {
    display: block !important; /* Show the beside-img div */
  }
}

@media screen and (min-width: 768px) and (max-width: 1600px) {
  .running-aff{
    margin: auto;
    position: absolute;
    right: 32%;
    top: 200px;
  }
 
  .girle-img,.girle-img-1{
    width: 250px;
  }
  .container {
    padding-top: 0;
  }
  .first-btn {
    padding-top: 20px;
    width: 250px;
    font-size: 10px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  .first-btn img {
    display: none;
  }
  .beside-img {
    display: block !important; /* Show the beside-img div */
  }
}