.second-parnet{
  background-image: url("https://storage.googleapis.com/anima-react/sectiontwo.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  padding-top: 64px;
}
.second-parnet {
  font-family: "Space Grotesk", sans-serif;
  color: #ffffff;

}

.second-parnet span {
  font-family: "base", sans-serif;

  font-size: 21px;
  font-weight: 300;
  line-height: 47px;
  text-align: left;
  color: #ffffff;
}
.second-parnet ul {
  list-style: none; /* Remove default list styles */
  padding-left: 0; /* Remove default list indentation */
}

.second-parnet ul:before {
  content: "";
  display: inline-block;
  width: 10px; /* Adjust width as needed */
  height: 10px; /* Adjust height as needed */
  background-color: #ffffff; /* White color */
  margin-right: 10px; /* Adjust margin as needed */
}


.second-parnet ul {
  font-family: "base", sans-serif;

  font-size: 35px;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: left;
}

.second-parnet p {
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 0em;
  text-align: left;
  font-family: "base", sans-serif;

}

@media screen and (max-width: 767px) {
  .second-parnet{
    padding-top: 0;
  }
  .second-parnet ul{
    padding-top: 0;
    font-size: 28px;
    font-weight: 500;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: center;
    
  }
  .second-parnet ul:before {
    content: "";
    display: none;
    width: 0; /* Adjust width as needed */
    height: 0; /* Adjust height as needed */
  }
  .wallet-div{
    padding: 0;
    margin: 0;

  }
}
