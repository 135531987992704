.sec-4-home {
  width: 100vw;
  height: 100vh;
  margin: auto;
  display: flex;
  background-image: url("https://storage.googleapis.com/anima-react/home/home-sec-4.png");
  flex-direction: column;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
}

.sec-4-head {
  margin-top: -60px;
  text-align: left;
  color: #ffffff;
}

.table-data {
  display: flex;
  /* justify-content: space-between; */
  justify-content: center;
  align-items: center;
  width: 98%;
  margin-left: 20px;
}
.table-data p:nth-child(1) {
  margin-left: 15px;
  text-align: left !important;
  width: 50% !important;
}

.table-data p:nth-child(2) {
  text-align: left !important;
  width: 100% !important;
}
.table-data p:nth-child(3) {
  text-align: left !important;
  width: 75% !important;
}
.table-data p:nth-child(4) {
  text-align: left !important;
  width: 80% !important;
}
.table-data p:nth-child(5) {
  text-align: left !important;
  width: 100% !important;
}
.table-data p:nth-child(6) {
  text-align: left !important;
  width: 100% !important;
}
.table-data p {
  text-align: left !important;
  color: #fff;
  width: 100%;
}

.sec-4-table {
  z-index: 890;
  background-image: url("https://storage.googleapis.com/anima-react/home/sec-4-chart.png");
  width: 1500px;
  background-repeat: no-repeat;
  height: 800px;
  background-size: contain;
  display: flex;
  flex-direction: column;
  margin: auto;
}
.sec-4-table-inner {
  display: flex;
  flex-direction: column;
  height: 65%;
  margin-top: 80px;
  width: 99%;
  margin-inline: auto;
  overflow-y: scroll;
}

@media (max-width: 1500px) {
  .sec-4-table {
    z-index: 890;
    background-image: url("https://storage.googleapis.com/anima-react/home/sec-4-chart.png");
    width: 1200px;
    background-repeat: no-repeat;
    height: 700px;
    background-size: contain;
    display: flex;
    flex-direction: column;
    margin: auto;
  }
}
@media (max-width: 1200px) {
  .sec-4-table {
    z-index: 890;
    background-image: url("https://storage.googleapis.com/anima-react/home/sec-4-chart.png");
    width: 700px;
    background-repeat: no-repeat;
    height: 400px;
    background-size: contain;
    display: flex;
    flex-direction: column;
    margin: auto;
  }
  .table-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 98%;
    margin-left: 0px;
  }
  .table-data p:first-child {
    text-align: left !important;
    width: 15%;
  }
  .sec-4-table-inner {
    display: flex;
    flex-direction: column;
    height: 65%;
    margin-top: 50px;
    width: 680px;
    margin-inline: auto;
    overflow-y: scroll;
  }
}
@media (max-width: 576px) {
  .sec-4-home {
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
  }
  .sec-4-table {
    z-index: 890;
    background-image: url("https://storage.googleapis.com/anima-react/home/sec-4-chart-sm.png");
    width: 320px;
    background-repeat: no-repeat;
    height: 700px;
    background-size: contain;
    display: flex;
    flex-direction: column;
    margin: auto;
  }
  .table-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 98%;
    margin-left: 0px;
  }
  .table-data p:nth-last-child(-n + 3) {
    display: none;
  }
  .table-data p:first-child {
    text-align: left !important;
    width: 40%;
  }
  .sec-4-table-inner {
    display: flex;
    flex-direction: column;
    height: 65%;
    margin-top: 50px;
    width: 270px;
    margin-inline: auto;
    overflow-y: scroll;
  }
}
