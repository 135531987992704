.fifth-parnet{
  background-image: url("https://storage.googleapis.com/anima-react/labor.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  padding-top: 64px;
}
.fifth-parnet {
  font-family: "Space Grotesk", sans-serif;
  color: #ffffff;

}
.create-aff-p{
  font-family: "Space Grotesk", sans-serif;

font-size: 22px !important;
font-weight: 300;
line-height: 47px;
letter-spacing: 0em;
text-align: left;

}

.fifth-parnet span {
  font-family: "base", sans-serif;

  font-size: 21px;
  font-weight: 300;
  line-height: 47px;
  text-align: left;
  color: #ffffff;
}
.fifth-parnet ul {
  list-style: none; /* Remove default list styles */
  padding-left: 0; /* Remove default list indentation */
}

.fifth-parnet ul:before {
  content: "";
  display: inline-block;
  width: 10px; /* Adjust width as needed */
  height: 10px; /* Adjust height as needed */
  background-color: #ffffff; /* White color */
  margin-right: 10px; /* Adjust margin as needed */
}


.fifth-parnet ul {
  font-family: "base", sans-serif;

  font-size: 35px;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: left;
}

.fifth-parnet p {
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 0em;
  text-align: left;
  font-family: "base", sans-serif;

}
.trade{
  width:100%;
}
.icons {
  display: flex;
  flex-wrap: wrap;
}

.icon-row {
  display: flex;
  flex: 1 0 25%; /* Three icons in one line */
  justify-content: space-around;
  align-items: center;
}
.icon-row div {
  padding: 10px !important;

}


.icons > div {
  padding: 10px !important;
}

.icons > div > img {
  width: 50px; /* Adjust size as needed */
  height: 50px; /* Adjust size as needed */
}


@media screen and (max-width: 767px) {

  .create-aff-p{
    font-family: Space Grotesk;
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;
  }

 

  .how-it-works{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .icon {
    width: 66px !important; /* Adjust size as needed */
    height:66px !important; /* Adjust size as needed */
  }
}
