.wallet-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: transparent !important; /* Transparent background */
    backdrop-filter: blur(10px); /* Apply blur effect */
     border-radius: 4%;
     padding: 16px;
  }
  
  .wallet-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px; /* Adjust spacing between buttons */
  }
  
  .wallet-button-content-1,
  .wallet-button-content-2,
  .wallet-button-content-3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 20vw; /* Adjust width as needed */
    padding: 10px;
    border-radius: 20px;
    background-color: #ffffff; /* Button background color */
    color: #333; /* Button text color */
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    border: none;
    cursor: pointer;
  }
  .wallet-button-content-1 {
    background-color: #0052FE;
    }
  
  .wallet-button-content:hover {
    background-color: #ffffff; /* Button background color on hover */
  }
  
  .wallet-button-content i {
    margin-right: 10px; /* Adjust spacing between icon and text */
  }
  @media screen and (max-width: 767px) {
  .walletconnect-icon,.metamask-icon,.coinbase-icon{
    width: 100px;
  }
 

  .wallet-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    background-color: transparent !important; /* Transparent background */
    backdrop-filter: blur(10px); /* Apply blur effect */

     border-radius: 4%;
     padding: 16px;
  }
  .wallet-button-content-1 {
  background-color: #0052FE;
  }
  .wallet-button-content-1,
  .wallet-button-content-2,
  .wallet-button-content-3 {
   
    width:50vw; /* Adjust width as needed */

  }
  }

  @media screen and (min-width: 768px) and (max-width: 1023px) {
    .walletconnect-icon,.metamask-icon,.coinbase-icon{
      width: 200px;
    }
   
  
    .wallet-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      
      background-color: transparent !important; /* Transparent background */
      backdrop-filter: blur(10px); /* Apply blur effect */
  
       border-radius: 4%;
       padding: 16px;
    }
    .wallet-button-content-1 {
    background-color: #0052FE;
    }
  
    .wallet-button-content-1,
    .wallet-button-content-2,
    .wallet-button-content-3 {
      width: 100%; /* Adjust width for larger screens */
    }
  }
  
  @media screen and (min-width: 1024px) and (max-width: 1600px) {
    .walletconnect-icon,.metamask-icon,.coinbase-icon{
      width: 300px;
    }
   
  
    .wallet-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      
      background-color: transparent !important; /* Transparent background */
      backdrop-filter: blur(10px); /* Apply blur effect */
  
       border-radius: 4%;
       padding: 16px;
    }
    .wallet-button-content-1 {
    background-color: #0052FE;
    }
  
    .wallet-button-content-1,
    .wallet-button-content-2,
    .wallet-button-content-3 {
      width: 100%; /* Adjust width for larger screens */
    }
  }