.nav-link {
  color: #ffffff !important; /* Update the color to white */
}
.nav-social-icons li a img:hover {
  color: #ffd23e;
}
.nav-social-icons li a img {
  transition: transform 0.3s ease;
}

.nav-social-icons li a img:hover {
  transform: scale(1.2); /* Increase size on hover */
  filter: brightness(1.2); /* Optional: increase brightness for hover effect */
}

.navbar {
  background-color: transparent !important; /* Keep the background transparent */
  padding-top: 32px;
}
.h-to-play,
.b-affiliate {
  color: #f4f4f4;
  text-decoration: none !important;
  padding: 0 32px !important;
  font-size: 1.5rem;
  text-align: left;
  font-family: "base", sans-serif;
  transition: color 0.3s, margin-left 0.3s, text-decoration 0.3s;
}
.nav-link.h-to-play.active,
.nav-link.h-to-play:hover {
  color: #ffd23e !important;
  margin-left: 0;
  text-decoration: underline;
}

.nav-link.b-affiliate.active,
.nav-link.b-affiliate:hover {
  color: #ffd23e !important;
  margin-left: 0;
  text-decoration: underline;
}
.dropdown-item {
  background-color: "black"; /* Keep the background transparent */
}
@media screen and (max-width: 767px) {
  .h-to-play,
  .b-affiliate {
    padding: 0 8px !important;
  }
  .nav-social-icons {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    padding-left: 8px;
  }
  .nav-social-icons li {
    padding: 0 8px;
  }
  .nav-social-icons li:hover {
    color: #ffd23e;
  }
  .nav-social-icons li {
    padding: 0 8px;
    animation-name: slideIn;
    animation-duration: 0.5s;
    animation-timing-function: ease;
  }

  @keyframes slideIn {
    from {
      transform: translateX(-20px);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

  .logo {
    width: 180px;
    height: auto;
  }
  .navbar-toggler:focus {
    outline: none;
    box-shadow: none;
    border: none;
  }
  .navbar-toggler {
    outline: none;
    box-shadow: none;
    border: none;
  }
  .toggle-icon {
    height: 51px;
  }
  .navbar-toggler-icon:focus {
    display: none;
  }
}
