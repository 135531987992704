.sec-2-home {
  width: 100vw;
  height: 100vh;
  margin: auto;
  display: flex;
  background-image: url("https://storage.googleapis.com/anima-react/home/home-sec-2-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.boxes-wraper {
  width: 60%;
  margin-block: 8vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sec-2-box-wrap {
  display: flex;
  width: 30%;
  background-image: url("https://storage.googleapis.com/anima-react/home/Group%20185.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.sec-2-box-wrap p {
  font-family: "base", sans-serif;
  text-transform: capitalize;
  margin-block: 10px;
  padding: 0;
}
.hero-txt-top-paid {
  width: 50%;
  color: #f4f4f4;
  margin: 0;
  padding: 0;
  font-size: 2.5rem;
  text-align: center;
  font-family: "base", sans-serif;
}

.bottom-hero-txt {
  color: #ffd23e;
  margin: 0;
  padding: 0;
  margin-left: 25px;
  margin-right: 15px;
  font-size: 2.5rem;
  text-align: center;
  font-family: "styled", sans-serif;
}
/* Define classes for different paragraph styles */
.sec-2-box-wrap p {
  font-family: "Space Grotesk", sans-serif;
}

.sec-2-box-wrap .title {
  color: #FFD23E;
  font-size: 1rem;
}

.sec-2-box-wrap .subtitle {
  color: #F4F4F4;
  font-size: 1.5rem;
}

.sec-2-box-wrap .amount {
  color: #F4F4F4;
  font-size: 2rem;
}
@media (max-width: 1400px) {
  .sec-2-box-wrap p {
    font-family: "Space Grotesk", sans-serif;
  }
  
  .sec-2-box-wrap .title {
    color: #FFD23E;
    font-size: 16px;
  }
  
  .sec-2-box-wrap .subtitle {
    color: #F4F4F4;
    font-size: 14px;
  }
  
  .sec-2-box-wrap .amount {
    color: #F4F4F4;
    font-size: 16px;
  }
  .boxes-wraper {
    width: 80%;
  }
  .sec-2-box-wrap {
    width: 30%;
  }
  .sec-2-box-wrap p {
    margin-block: 2px !important;
  }
}


@media (max-width: 1200px) {
  .sec-2-box-wrap p {
    font-family: "Space Grotesk", sans-serif;
  }
  
  .sec-2-box-wrap .title {
    color: #FFD23E;
    font-size: 16px;
  }
  
  .sec-2-box-wrap .subtitle {
    color: #F4F4F4;
    font-size: 14px;
  }
  
  .sec-2-box-wrap .amount {
    color: #F4F4F4;
    font-size: 16px;
  }
  .boxes-wraper {
    padding: 16px;
    width: 90%;
  }
  .sec-2-box-wrap {
    width: 30%;
  }
  .sec-2-box-wrap p {
    margin-block: 2px !important;
  }
}

@media (max-width: 767px) {
  .formateRation{
    font-size: 22px !important;
  }
  .sec-2-home {
    background-size: cover;
    background-position-x: center;
  }
  .boxes-wraper {
    width: 90% !important;
    flex-direction: column;
    margin-block: 30px;
  }
  .hero-txt-top-paid {
    width: 80% !important;
    font-size: 1.7rem !important;
    text-align: center;
  }

  .bottom-hero-txt {
    font-size: 1rem !important;
  }
  .sec-2-box-wrap {
    width: 100%;
    margin-block: 10px;
  }
  .sec-2-box-wrap p {
    margin-block: 4px !important;
  }
}
