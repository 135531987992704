.container {
  padding-top: 15vh;
}

.how-to-plaly-title {
  color: #ffffff;
  font-size: 63px;
  font-weight: 500;
  line-height: 67px;
  letter-spacing: 0em;
  text-align: left;
  font-family: "base", sans-serif;
}
span {
  color: #ffd23e;
  font-size: 65px;
  font-weight: 400;
  line-height: 104px;
  letter-spacing: 0.052em;
  text-align: left;
  font-family: "styled", sans-serif;
}
.how-to-plaly-subtitle {
  color: #ffffff;
  font-family: "base", sans-serif;
  font-size: 32px;
  font-weight: 300;
  line-height: 47px;
  letter-spacing: 0em;
  text-align: left;
}
.rotate-title {
  color: #ffd23e;
  font-size: 65px;
  font-weight: 400;
  line-height: 104px;
  letter-spacing: 0.052em;
  text-align: left;
  font-family: "styled", sans-serif;
 position: absolute;
 bottom: 10%;
 right: 10%;
  transform: rotate(6deg); /* Rotate the text counter-clockwise */
  margin-bottom: 20px; /* Add margin to prevent overlapping */
}
@media screen and (max-width: 767px) {
  .container{
    padding-top: 2vh;
  }
  .how-to-plaly-subtitle{
    font-size: 21px;
  }
  .how-to-plaly-title {
    color: #ffffff;
    font-size: 32px;
    font-weight: 500;
    text-align: left;
    font-family: "base", sans-serif;
  }
  .first-section-header{
    padding: 0 !important;
    margin: 0 !important;
  }
  .span {
    font-size: 38px;
    font-weight: 400;
    line-height: 104px;
    letter-spacing: 0.052em;
    text-align: left;
  }

  .rotate-title {
    color: #ffd23e;
      transform: rotate(0deg) !important; /* Rotate the text counter-clockwise */
    font-family: "styled", sans-serif;
    font-size: 43px;
font-weight: 400;
line-height: 57px;

  }
  .container {
    padding-top: 0 !important;
  }
}
