.sec-9-home {
  width: 100vw;
  height: 100vh;
  margin: auto;
  display: flex;
  background-image: url("https://storage.googleapis.com/anima-react/home/home-sec-3.png");
  flex-direction: column;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
}

.sec-9-txt-top {
  color: #fff;
  margin: 0;
  padding: 0;
  font-size: 3.5rem;
  text-align: left;
  font-family: "base", sans-serif;
  padding-top: 16px;
}

.bottom-sec-9-txt {
  color: #f4f4f4;
  margin: 0;
  padding: 0;
  margin-left: 25px;
  margin-right: 15px;
  font-size: 3.5rem;
  text-align: center;
  font-family: "styled", sans-serif;
}

.sec-9-txt-para {
  color: #fff;
  width: 50%;
  max-width: 700px;
  text-align: center;
  margin: 0;
  padding-top: 40px;
  font-size: 1.3rem;
  font-family: "base", sans-serif;
}

.sec-9-txt-para2 {
  color: #fff;
  width: 50%;
  max-width: 700px;
  text-align: center;
  padding-top: 20px;
  margin: 0;
  font-size: 1.3rem;
  font-family: "base", sans-serif;
}
.sec-9-txt-botm {
  color: #ffd23e;
  max-width: 700px;
  text-align: center;
  margin-block: 0px;
  margin-inline: 30px;
  font-size: 1.1rem;
  font-family: "base", sans-serif;
  cursor: pointer;
}
.sec-9-txt-botm:hover {
  cursor: pointer;
  color: #ffd23e;
}
.sec-9-bottom-btm {
  display: flex;
  align-items: center;
  padding-top: 50px;
}
.sec-9-bottom-img {
  display: flex;
  justify-content: flex-end;
  width: 80%;
}

@media (max-width: 1200px) {
  .sec-9-txt-top {
    font-size: 2.5rem;
  }

  .bottom-sec-9-txt {
    font-size: 2.5rem;
  }
  .sec-9-txt-para {
    padding-top: 20px;
    font-size: 1.3rem;
  }

  .sec-9-txt-para2 {
    padding-top: 10px;
    font-size: 1.1rem;
  }
  .sec-9-bottom-img img {
    padding-top: 40px;
    width: 35%;
  }
}

@media (max-width: 576px) {
  .sec-9-home {
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
  }
  .sec-9-txt-top {
    font-size: 1.3rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center ;
  }
  .sec-9-bottom-btm {
    flex-direction: column;
  }
  .bottom-sec-9-txt {
    font-size: 1.3rem;
  }
  .sec-9-txt-para {
    width: 80%;
    padding-top: 16px;
    font-size: 1rem;
  }

  .sec-9-txt-para2 {
    width: 80%;
    padding-top: 10px;
    font-size: 22px;
  }
  .sec-9-bottom-img {
    justify-content: center;
    width: 100%;
  }
  .sec-9-bottom-img img {
    padding-top: 40px;
    width: 70%;
  }
  .sec-9-txt-botm {
   padding-top: 16px;

  }
}
