.btn .btn-header-link{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
#main{
  background-image: url("https://storage.googleapis.com/anima-react/tenbackground.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  padding-top: 64px;
}

.card{
  background-color: transparent !important;
  margin: 16px;
}
.accordion{
  border: 1px solid #FFD23E;
  border-radius: 10px;
}
.card-body{
  font-family: "Space Grotesk", sans-serif;
  color: #ffffff;
font-size: 16px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: left;


}
.card-header,.card-body{
  background-color: #150C25;
}
.card-header{
  border: 1px solid #FFD23E;
}
.arrow-wrapper {
  float: right;
  margin-top: 6px; /* Adjust as needed */
}

.arrow {
  display: flex;
  align-items: right;
  justify-content: right;
  color: #FFD23E;
  font-size: 1.5rem; /* Adjust as needed */
}
.text span{
  font-family: "Space Grotesk", sans-serif;
font-size: 21px;
font-weight: 300;
line-height: 47px;
letter-spacing: 0em;
color: #ffffff;
text-align: left;

}
.text h2:before {
  display: flex;
  justify-content: center;
  align-items: center;
  content: "";
  display: inline-block;
  width: 10px; /* Adjust width as needed */
  height: 10px; /* Adjust height as needed */
  background-color: #ffffff; /* White color */
  margin-right: 10px; /* Adjust margin as needed */
}
.text h2{
  color: #ffffff;
}
.text p{
  color: #ffffff;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.back{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #A128C4;
  border-radius: 50%;
  height: 80px;
  width: 80px;
}
.img-with-drop{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}