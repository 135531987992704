.herosechome {
  width: 100vw;
  height: 84vh;
  max-height: 100vh;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.herobhimg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  object-fit: fill;
  width: 100.5vw;
  height: 100%;
}
.hero-txt-top {
  width: 50%;
  color: #f4f4f4;
  margin: 0;
  padding: 0;
  font-size: 1.5rem;
  text-align: left;
  font-family: "base", sans-serif;
}

.bottom-hero-txt-home {
  color: #f4f4f4;
  margin: 0;
  padding: 0;
  font-size: 1.5rem;
  text-align: center;
  font-family: "base", sans-serif;
  text-transform: capitalize;
}
.hero-txt-botm {
  width: 50%;
  color: #ffd23e;
  font-size: 2.5rem;
  margin: 0;
  padding: 0;
  text-align: right;
  font-family: "styled", sans-serif;
}
.crypto-clash-img {
  width: 50%;
  padding-block: 10px;
}

.bottom-hero-sec {
  position: absolute;
  bottom: 10%;
  width: 90%;
  display: flex;
  align-items: center;
  margin-inline: auto;
  justify-content: space-between;
}
.left-icon-hero {
  background: #ffd23e;
  max-width: 350px;
  width: 250px;
  min-width: 200px;
  cursor: pointer;
  height: 6.5vh;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.play-earn-btn {
  padding-block: 20px;
}
@media (max-width: 576px) {
 
  #hero-video{
   width: 100% !important;
   height: 100% !important;
  }
  .herobhimg {
    object-fit: cover;
    width: 100vw;
  }
  .hero-txt-top {
    width: 80% !important;
    font-size: 0.8rem !important; /* Smaller font size for small screens */
  }
  .hero-txt-botm {
    width: 80% !important;
    font-size: 0.8rem !important;
  }
  .crypto-clash-img {
    width: 80%;
    padding-block: 10px;
  }
  .play-earn-btn {
    padding-top: 100px;
  }
  .bottom-hero-sec {
    flex-direction: column;
    position: relative;
    margin-top: 30%;
    width: 100%;
  }
  .right-icon-hero {
    display: none !important;
  }
  .herosechome {
  padding-top: 0 !important;
  margin-top: 0 !important;
  }
  
}

@media (max-width: 1200px) {
  .hero-txt-top {
    width: 70% !important;
    font-size: 1.5rem !important; /* Smaller font size for small screens */
  }
  .hero-txt-botm {
    width: 70% !important;
    font-size: 1.5rem !important;
  }
  .crypto-clash-img {
    width: 70%;
    padding-block: 10px;
  }
}
