.sec-2-home {
  width: 100vw;
  height: 100vh;
  margin: auto;
  display: flex;
  background-image: url("https://storage.googleapis.com/anima-react/home/home-sec-2-bg.png");
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.sec-5-contain-main {
  width: 80%;
  margin-inline: auto;
}
.sec-5-txt-top {
  width: 50%;
  color: #f4f4f4;
  margin-bottom: 20px;
  padding: 0;
  font-size: 3.5rem;
  text-align: left;
  font-family: "base", sans-serif;
}
.sec-5-right-icon-hero {
  float: right;
  width: 13%;
}
.sec-5-hero-txt {
  color: #ffd23e;
  margin: 0;
  padding: 0;
  margin-left: 25px;
  margin-right: 15px;
  font-size: 3.5rem;
  text-align: center;
  font-family: "styled", sans-serif;
}
.sec-5-contain {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.sec-t-contain-child {
  width: 50%;
}
.sec-5-contain p {
  color: #f4f4f4;
  margin-block: 20px;
  padding: 0;
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  text-align: left !important;
  font-family: "base", sans-serif;
  display: flex;
  justify-content: flex-start !important;
}

@media (max-width: 1200px) {
  .sec-5-right-icon-hero {
    width: 20%;
  }
  .sec-5-contain p {
    margin-block: 10px;
    align-items: center;
    font-size: 1rem;
  }
  .sec-5-txt-top {
    margin-bottom: 10px;
    padding: 0;
    font-size: 2.5rem;
  }
}

@media (max-width: 576px) {
  .sec-5-right-icon-hero {
    align-self: center;
    width: 50%;
  }
  .sec-t-contain-child {
    width: 100%;
  }
  .sec-5-contain {
    width: 100%;
    flex-direction: column;
  }
  .sec-5-txt-top {
    width: 100%;
    text-align: center;
  }
  .sec-5-contain p {
    width: 100%;
    margin-block: 10px;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-size: 0.8rem;
  }
  .sec-5-txt-top {
    margin-bottom: 10px;
    padding: 0;
    font-size: 1.5rem;
  }
  .certik {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
