.fifth-parnet{
  background-image: url("https://storage.googleapis.com/anima-react/fifthsection.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  padding-top: 64px;
}
.fifth-parnet {
  font-family: "Space Grotesk", sans-serif;
  color: #ffffff;

}

.fifth-parnet span {
  font-family: "base", sans-serif;

  font-size: 21px;
  font-weight: 300;
  line-height: 47px;
  text-align: left;
  color: #ffffff;
}
.fifth-parnet ul {
  list-style: none; /* Remove default list styles */
  padding-left: 0; /* Remove default list indentation */
}

.fifth-parnet ul:before {
  content: "";
  display: inline-block;
  width: 10px; /* Adjust width as needed */
  height: 10px; /* Adjust height as needed */
  background-color: #ffffff; /* White color */
  margin-right: 10px; /* Adjust margin as needed */
}


.fifth-parnet ul {
  font-family: "base", sans-serif;

  font-size: 35px;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: left;
}

.fifth-parnet p {
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 0em;
  text-align: left;
  font-family: "base", sans-serif;

}
.trade{
  width:100%;
}

@media screen and (max-width: 767px) {
  .fifth-parnet{
    padding-top: 0 !important;
}
.fifth-parnet ul:before {
  content: "";
  display: none !important;
  width: 0; /* Adjust width as needed */
  height: 0; /* Adjust height as needed */
  margin-right: 0; /* Adjust margin as needed */
}
.fifth-title{
  font-size: 24px !important;
  font-weight: 500;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: center !important;
  display: flex;
  justify-content: center;
  align-items: center;
  
}
}