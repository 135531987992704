.sec-3-home {
  width: 100vw;
  height: 100vh;
  margin: auto;
  display: flex;
  background-image: url("https://storage.googleapis.com/anima-react/home/home-sec-3.png");
  flex-direction: column;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  justify-content: center;
}
.golden-text {
  color: golden !important;
}

.normal-font{
  font-family: "Space Grotesk", sans-serif !important;
letter-spacing: 0em;
text-align: center;


}

.sec-3-price-chart {
  display: flex;
  width: 80%;
  justify-content: center;
}

.sec-3-price-chart-child {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: 1px solid #ffd23e; */
  padding-inline: 40px;
  padding-block: 20px;
  justify-content: space-between;
}
.table-best-opt {
  position: absolute;
  margin-left: 200px;
  margin-top: -100px;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  transform: rotate(-20deg);
  align-items: center;
  background-color: #6c5747;
}
.table-best-opt p {
  padding: 0;
  text-wrap: wrap;
  margin: 0;
  font-size: 1.3rem;
  text-align: center;
  color: #ffd23e;
  font-family: "styled", sans-serif;
}
.sec-3-mobile-first-p {
  padding: 0;
  text-wrap: wrap;
  margin: 0;
  font-size: 1.5rem;
  text-align: center;
  color: #fff;
  font-family: "base", sans-serif;
}
.sec-3-mobile-head-container {
  display: flex;
  justify-content: space-between;
  width: 90vw;
}
.sec-3-mobile-head-child {
  width: 30%;
  border: 1px solid #ffd23e;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-block: 16px;
}
.sec-3-mobile-head-child p {
  padding: 0;
  text-wrap: wrap;
  margin: 0;
  font-size: 0.9rem;
  text-align: center;
  font-family: "base", sans-serif;
}
.sec-3-price-chart-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.sec-3-price-chart-body-child {
  display: flex;
  align-items: center;
  padding-block: 10px;
  margin-block: 5px;
  justify-content: center;
}
.sec-3-price-chart-body-child p {
  color: #fff;
  padding: 0;
  margin: 0;
  font-size: 1rem;
  margin-left: 5px;
  text-align: left;
  font-family: "base", sans-serif;
}
.sec-3-price-chart-head {
  display: flex;
  flex-direction: column;
  color: #ffffff;
}
.pricing-icon {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pricing-icon-cross {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-line {
  position: absolute;
  display: flex;
  align-items: center;
  width: 800px;
  margin-top: 100px;
  margin-inline: auto;
  color: #ffffff !important;
}
.sec-3-chart {
  z-index: 891;
  background-image: url("https://storage.googleapis.com/anima-react/home/sec-3-chart.png");
  width: 1000px;
  max-width: 1400px;
  min-width: 500px;
  background-repeat: no-repeat;
  height: 650px;
  max-height: 1000px;
  min-height: 300px;
  background-size: contain;
  display: flex;
  margin: auto;
}
.sec-3-btn {
  margin-top: -10px;
  margin-left: -10px;
  /* margin-bottom: 100px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (min-width: 1800px) {
  .sec-3-price-chart-child {
    width: 380px;
    height: 80%;
  }
  .table-line {
    width: 1400px;
    margin-top: 65px;
  }
}
@media (max-width: 800px) {
  .table-line {
    width: 700px;
  }
}
@media (max-width: 1200px) {
  .sec-3-chart {
    width: 600px;
    height: 500px;
  }
}

@media (max-width: 576px) {
  .sec-3-btn {
    margin-top: 10px;
  }
  .sec-3-price-chart-body {
    width: 100%;
  }
  .sec-3-price-chart-body-child {
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .sec-3-home {
    background-size: cover;
    background-position-x: right;
    background-repeat: no-repeat;
  }
  .sec-3-chart {
    min-width: 250px;
    width: 90%;
    max-width: 600px;
    height: 300px;
  }
}
