.sec-2-home {
  width: 100vw;
  height: 100vh;
  margin: auto;
  display: flex;
  background-image: url("https://storage.googleapis.com/anima-react/home/home-sec-2-bg.png");
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.boxes-wraper {
  width: 60%;
  margin-block: 8vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sec-7-box-wrap {
  display: flex;
  width: 40%;
  background-image: url("https://storage.googleapis.com/anima-react/home/Group%20185.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.sec-7-box-wrap p {
  font-family: "base", sans-serif;
  text-transform: capitalize;
  margin-block: 10px;
  padding: 0;
}
.hero-txt-top {
  width: 50%;
  color: #f4f4f4;
  margin: 0;
  padding: 0;
  font-size: 2.5rem;
  text-align: left;
  font-family: "base", sans-serif;
}

.bottom-hero-txt {
  color: #ffd23e;
  margin: 0;
  padding: 0;
  margin-left: 25px;
  margin-right: 15px;
  font-size: 2.5rem;
  text-align: center;
  font-family: "styled", sans-serif;
}
