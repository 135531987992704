.seven-parnet {
  background-image: url("https://storage.googleapis.com/anima-react/labor.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  padding-top: 64px;
}
.seven-parnet {
  font-family: "Space Grotesk", sans-serif;
  color: #ffffff;
}

.seven-parnet span {
  font-family: "base", sans-serif;

  font-size: 21px;
  font-weight: 300;
  line-height: 47px;
  text-align: left;
  color: #ffffff;
}
.seven-parnet ul {
  list-style: none; /* Remove default list styles */
  padding-left: 0; /* Remove default list indentation */
}

.seven-parnet ul:before {
  content: "";
  display: inline-block;
  width: 10px; /* Adjust width as needed */
  height: 10px; /* Adjust height as needed */
  background-color: #ffffff; /* White color */
  margin-right: 10px; /* Adjust margin as needed */
}

.seven-parnet ul {
  font-family: "base", sans-serif;

  font-size: 35px;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: left;
}

.seven-parnet p {
  font-family: "Space Grotesk", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: center;
}
.btn {
  background-color: transparent;
  font-family: "Space Grotesk", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 52px;
  letter-spacing: 0.109em;
  text-align: center;
  color: #ffd23e;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .seven-parnet {
    padding-top: 0 !important;
  }
  .col-md-6 img {
    padding-top: 0 !important;
    margin: 0 !important;
  }
  .seven-parnet .container .row,
  .align-center {
    padding: 0 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center;
  }
  .seven-parnet p {
    font-size: 14px !important;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;

  }
  .seven-parnet ul {
    font-size: 28px;
    font-weight: 500;
    line-height: 67px;
    letter-spacing: 0em;
    text-align: center;
  }
  .seven-image {
    padding: 0;
    width: 250px;
  }
  .seven-parnet h3 {
    font-family: "Space Grotesk", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;
  }
    
}
